// General Rules
*,
html * {
  vertical-align: baseline;
  font-weight: inherit;
  font-family: inherit;
  font-style: inherit;
  font-size: 1em;
  letter-spacing: 0;
  box-sizing: border-box;
}

#root, #AppContainer.no-scroll {
  overflow: hidden;
  height: 98vh;
}

button {
  padding: 0;
}

img {
  max-width: 100%;
}

*:focus {
  outline: 0 !important;
}

a {
  text-decoration: none;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

p {
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

@media (hover: none) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'] {
    font-size: 1rem !important;
  }
}
