@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

$bg-gradient: linear-gradient(180deg, #31405f 0%, #6e82bd 218.25%);

.ChannelCardWrap {
  position: relative;
  overflow: hidden;
  @media (min-width: $desktop-sd) {
    &:hover {
      & .ChannelCard__favorites-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        transition: all $transition;
        &:hover {
          background: $overlay;
        }

        & + a:before {
          content: '';
          position: absolute;
          border: 5px solid #33a49f;
          box-sizing: border-box;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
        }

        & + a .ChannelCard__overlay-btn {
          opacity: 1;
        }
      }
    }
  }
}

.ChannelCard {
  $self: &; // ref to component (&)
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  place-items: center;
  background: $tvChannelCard;
  padding: 25px;
  cursor: pointer;
  border-radius: 9px;
  width: 100%;
  height: 100%;
  border: none;

  & img {
    width: 100%;
  }

  &__favorites-btn {
    display: none;
    background: none;
    border: none;
    position: absolute;
    z-index: 1;
    top: 10px;
    right: 10px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    transition: all $transition;
    &:hover {
      background: $overlay;
    }
  }

  @media (min-width: $desktop-sd) {
    display: grid;

    &:hover {
      &:before {
        content: '';
        position: absolute;
        background: $overlay;
        border-radius: 9px;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }
      // #{ $self }__nameChannel {
      //   display: block;
      //   font-weight: 500;
      //   font-size: 13px;
      //   color: #ffffff;
      //   position: absolute;
      //   background-color: #33a49f;
      //   bottom: -21px;
      //   width: 100%;
      //   padding: 5px;
      // }
    }
  }

  // @media (min-width: 1200px) {
  //   min-height: rem(187px);
  // }
  @media (min-width: $desktop-sd) {
    &:hover:not(&--locked) {
      #{ $self }__overlay-btn {
        opacity: 1;
      }
    }
  }

  &__nameChannel {
    display: block;
    @include typography(base, $medium);
    line-height: 19px;
    color: $mainText;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    white-space: nowrap;
    padding: 0 10px;
    max-width: 100%;
  }

  // &--locked {
  //   &::after {
  //     background-image: url(../../../assets/images/channels/lock.svg);
  //     background-repeat: no-repeat;
  //     background-size: 20%;
  //     background-position: center;
  //   }

  //   &:before {
  //     content: '';
  //     position: absolute;
  //     // background: $overlay;
  //     border-radius: 9px;
  //     box-sizing: border-box;
  //     width: 100%;
  //     height: 100%;
  //     top: 0;
  //     left: 0;
  //     bottom: 0;
  //     right: 0;
  //   }

  //   // img {
  //   //   opacity: 0.2;
  //   // }
  // }

  &__overlay-btn {
    opacity: 0;
    width: 3.25rem;
    height: 3.25rem;
    position: absolute;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%);

    &::after {
      width: 2rem;
      height: 2rem;
    }

    &::before {
      content: attr(data-content);
      color: $mainText;
      @include typography(subtitleSm, $medium);
      position: absolute;
      bottom: -34px;
    }
  }

  &__overlay-btn.channel__player {
    opacity: 1;
  }

  &__wrap {
    display: grid;
  }
  &__duration {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    padding: 5px 5px 0;
    color: #acb5cd;
  }
  &__subscription {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    padding: 5px 5px 0;
    color: #6d88d2;
  }

  &__link-wrapper {
    padding: 1px;
    aspect-ratio: 1/1;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.15),
      rgba(255, 255, 255, 0.3)
    );
    margin-bottom: 10px;
    border-radius: 10px;

    @media (min-width: $desktop-sd) {
      &:hover {
        background: $accent;
      }
    }
  }

  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.ChannelTabsSkeleton {
  & .TopTabs {
    display: flex;
    padding-top: 26px;
    gap: 36px;
    padding-bottom: 40px;
    overflow: hidden;

    & .ChannelTabSkeleton {
      @media only screen and (min-width: $desktop-sd) {
        width: 150px;
        height: 30px;
      }
      min-width: 140px;
      width: 140px;
      height: 22px;
    }
  }

  & .BottomTabs {
    & .ChannelTabSkeleton {
      @media only screen and (min-width: $desktop-sd) {
        width: 250px;
        height: 40px;
      }
      min-width: 150px;
      width: 150px;
      height: 26px;
    }
  }
}

.ChannelCardSkeleton {
  aspect-ratio: 1/1;

  &__image {
    flex: 1;
    width: 100%;
    height: 100%;
    margin-bottom: 12px;
  }

  & .CardsItemSkeleton__title {
    height: 16px;
    padding: 0 10px;
    margin-bottom: 8px;
    width: 100%;
  }
}
